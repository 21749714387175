<template>
  <Layout :footer-style="1">
    <div class="container">
      <Breadcrumb root-url="/security-privacy" parent-url="安全隐私" current-url="威胁情报" style="border: none;"/>
    </div>

    <div class="rn-service-area rn-section-gapBottom pt--100 bg-color-light area-2">
      <div class="container">
        <div class="product">
          <img class="pic" src="@/assets/images/product/img_5.png" />
          <div class="about">
            <div class="title">威胁情报</div>
            <div class="content">
              根据Gartner对威胁情报的定义，威胁情报是某种基于证据的知识，包括上下文、机制、标示、含义和能够执行的建议，这些知识与资产所面临已有的或酝酿中的威胁或危害相关，可用于资产相关主体对威胁或危害的响应或处理决策提供信息支持。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gapBottom pt--60 area-2">
      <div class="container">

        <div class="row">
          <div class="col-lg-12">
            <div class="section-title" data-aos="fade-up">
              <h3 class="title color-gray">微步</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-40.png">
            <div class="company-info">
              微步在线是中国新一代网络安全公司代表公司 ，数字时代网络威胁应对专家 。公司主要成员来自于亚马逊、微软、BAT、美团等公司。微步在线专注于精准、高效、智能的网络威胁发现和响应，开创并引领中国威胁情报行业的发展，提供“云+流量+端点”全方位威胁发现和响应产品及服务，帮助客户建立全生命周期的威胁监控体系和安全响应能力 ，现已服务了国家电网、中石油、工商银行、招商银行、小米、京东、中信集团等来自能源、金融、智能制造、互联网等行业的大型企业客户。
            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">安恒</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-38.png">
            <div class="company-info">
              安恒信息以云安全、大数据安全、物联网安全、智慧城市安全、工业控制系统安全及工业互联网安全五大方向为市场战略。凭借研发实力和持续的产品创新，已形成覆盖网络信息安全生命全周期的产品体系，包括网络信息安全基础产品、网络信息安全平台以及网络信息安全服务，各产品线及业务线在行业中均形成竞争力( [49])。2022年，科创板开市三周年之际公布的《2022科创板创新力榜单》中，安恒信息入选并成为唯一一支网络安全股。( [50])安恒信息已形成完善的网络安全、数据安全、商用密码应用等综合信创解决方案。
            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">奇安信</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-30.png">
            <div class="company-info">
              奇安信成立于2014年，坐落于中国北京市，专注于网络安全领域，向政府、企业用户提供新一代企业级网络安全产品和服务。凭借持续的研发创新和以实战攻防为核心的安全能力，已发展成为国内领先的基于大数据、人工智能和安全运营技术的网络安全供应商。同时，奇安信是2022年冬奥会和冬残奥会网络安全服务与杀毒软件的官方赞助商。
            </div>
          </div>
        </div>


      </div>
    </div>
    <!-- End Service Area -->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Icon from '@/components/icon/Icon'
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
export default {
  name: 'Product',
  components: {
    SectionTitle,
    Separator,
    Icon,
    Breadcrumb,
    Layout
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.display-block{
  display: block;
}
.area-2{
  .icon {
    .p-img {
      width: 64px;
      height: 64px;
    }
  }
  .more{
    display: inline-flex;
    color: #2261AA;
    img {
      width: 27px;
      height: 27px;
    }
    .i1{
      margin-left: -4px;
    }
    .i2{
      margin-left: -20px;
    }
  }
}
.product{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .pic{
    height: 220px;
  }
  .about{
    margin-left: 40px;
    color: #000000;
    .title{
      font-size: 32px;
      font-weight: bold;
    }
    .content{
      margin-top: 12px;
      font-size: 20px;
    }
  }
}
.company-logo{
  width: 320px;
}
.company-info{
  font-size: 24px;
  color: #000000;
  text-align: left;
  margin-top: 20px;
}
</style>
